(function () {
  "use strict";

  function testimonialSlider() {
    if (document.querySelector('.testimonial-slider')) {
      const swiper = new Swiper('.testimonial-slider .swiper', {
        loop: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // },
        navigation: {
          nextEl: '.testimonial-slider .slider-button-next',
          prevEl: '.testimonial-slider .slider-button-prev',
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      })
    }
  }
  function videoSlider() {
    if (document.querySelector('.video-slider')) {
      const swiper = new Swiper('.video-slider .swiper', {
        loop: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // },
        navigation: {
          nextEl: '.video-slider .slider-button-dortgen-next',
          prevEl: '.video-slider .slider-button-dortgen-prev',
        },
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false,
        // },
      })
    }
  }
  function firmaFotoSlider() {
    if (document.querySelector('.firma-foto-slider')) {
      const swiper = new Swiper('.firma-foto-slider .swiper', {
        loop: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // },
        navigation: {
          nextEl: '.firma-foto-slider .slider-button-dortgen-next',
          prevEl: '.firma-foto-slider .slider-button-dortgen-prev',
        },
        slidesPerView: 2,
        spaceBetween: 10,
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false,
        // },
        breakpoints: {
          992: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      })
    }
  }

  function altiKareSlider() {
    if (document.querySelector('.alti-kare-slider')) {
      const swiper = new Swiper('.alti-kare-slider .swiper', {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        slidesPerView: 1,
        spaceBetween: 10,
        // navigation: {
        //   nextEl: '.firma-foto-slider .slider-button-dortgen-next',
        //   prevEl: '.firma-foto-slider .slider-button-dortgen-prev',
        // },
        // autoplay: {
        //   delay: 2500,
        //   disableOnInteraction: false,
        // },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        },
      })
    }
  }

  function reklamSlider() {
    if (document.querySelector(".reklam-slider")) {
      const swiper = new Swiper(".reklam-slider .swiper", {
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      });
    }

  }


  // // Uluslararası Telefon Input
  // function telefonInput() {
  //   // Slider Altı Form
  //   if (document.querySelector('#telInput')) {
  //     var input = document.querySelector("#telInput");
  //     window.intlTelInput(input, {
  //       utilsScript: "assets/scripts/utils.js"
  //     });
  //   }
  //   // Modal Form Tel
  //   if (document.querySelector('#modalTel')) {
  //     var input = document.querySelector("#modalTel");
  //     window.intlTelInput(input, {
  //       utilsScript: "assets/scripts/utils.js"
  //     });
  //   }
  // }

  function initAcc(elem, option) {
    document.addEventListener('click', function (e) {
      if (!e.target.matches(elem + ' .a-btn')) return;
      else {
        if (!e.target.parentElement.classList.contains('active')) {
          if (option == true) {
            var elementList = document.querySelectorAll(elem + ' .a-container');
            Array.prototype.forEach.call(elementList, function (e) {
              e.classList.remove('active');
            });
          }
          e.target.parentElement.classList.add('active');
        } else {
          e.target.parentElement.classList.remove('active');
        }
      }
    });
  }



  // Scroll top Button

  // function scrollTop() {
  //   const btnScrollTop = document.getElementById('scrollTop')
  //   document.addEventListener('scroll', () => {
  //     if (window.scrollY > 1000) {
  //       btnScrollTop.classList.remove('hide-btn');
  //     } else {
  //       btnScrollTop.classList.add('hide-btn');
  //     }
  //   })

  //   btnScrollTop.addEventListener('click', () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth"
  //     });
  //   })
  // }

  function showPassword() {
    if (document.querySelector('[data-show-pass]')) {
      const button = document.querySelectorAll('[data-show-pass]')

      button.forEach(function (btn) {
        const btnIcon = btn.querySelector('.icon')
        const input = btn.previousElementSibling

        btn.addEventListener('click', function () {
          if (input.type === 'password') {
            input.type = 'text'
            btnIcon.classList = "icon icon-eye"
          } else {
            input.type = 'password'
            btnIcon.classList = "icon icon-eye-slash"
          }
        })
      })
    }
  }


  testimonialSlider()
  videoSlider()
  firmaFotoSlider()
  altiKareSlider()
  reklamSlider()
  showPassword()
  // telefonInput()
  // onceSonraSlider()
  initAcc('.acc.v1', true);
  // scrollTop()
  Fancybox.bind()
})();